import { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceEdition, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { CASF_COHORTS } from '../experiment-config';

import { useExperimentEnrollment } from './useExperimentEnrollment';

export const useFireQuickSummaryExposureEvent = ({
	skip,
	experimentName,
}: {
	skip: boolean;
	experimentName: string;
}) => {
	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { cohort } = useExperimentEnrollment(experimentName);

	// eslint-disable-next-line confluence-feature-gating/static-feature-gates
	const [, fireExperimentExposure] = UNSAFE_noExposureExp(experimentName);

	const fireQuickSummaryExposureEvent = useCallback(() => {
		if (
			!edition ||
			skip ||
			edition === ConfluenceEdition.PREMIUM ||
			cohort === CASF_COHORTS.NOT_ENROLLED
		) {
			return;
		}

		void fireExperimentExposure();

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'exposed',
				actionSubject: 'experiment',
				actionSubjectId: `${experimentName}-exposed`,
				attributes: {
					edition,
					cohort,
					isSiteAdmin,
				},
				source: experimentName,
			},
		}).fire();
	}, [
		cohort,
		createAnalyticsEvent,
		edition,
		experimentName,
		isSiteAdmin,
		skip,
		fireExperimentExposure,
	]);

	return { fireQuickSummaryExposureEvent };
};
