import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { xcss, Text, Box, Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';

import { SPAViewContext } from '@confluence/spa-view-context';

import { QuickAISummaryButton } from './QuickAISummaryButton';
import { QuickAISummaryInterestedUsers } from './QuickAISummaryInterestedUsers';

const i18n = defineMessages({
	QuickAISummaryUpsellTitleText: {
		id: 'experiment-quick-summary.title.text',
		defaultMessage: 'Too much to read? Get the key points with AI',
		description: 'Header text for Quick AI Summary upsell',
	},
	QuickAISummaryUpsellBodyText: {
		id: 'experiment-quick-summary.body.text',
		defaultMessage:
			'Summarize this page with <link>Atlassian Intelligence</link> and spend time on work that matters. Start a free, 30-day Premium trial to try.',
		description: 'Body text explaining the value of Quick AI Summary',
	},
});

const CTAStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const BodyStyle = xcss({
	paddingTop: 'space.250',
	paddingBottom: 'space.250',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	borderRadius: '3px',
	border: `1px solid ${token('color.border')}`,
	backgroundColor: 'elevation.surface.overlay',
});

const NumUsersTextStyles = xcss({
	display: 'flex',
	justifyContent: 'align-right',
});

const ATLASSIAN_INTELLIGENCE_LINK = 'https://www.atlassian.com/platform/artificial-intelligence';

export const QuickAISummaryBody = ({ usersInterested }: { usersInterested: number }) => {
	const { isSiteAdmin } = useContext(SPAViewContext);

	return (
		<Box xcss={BodyStyle}>
			<Stack space="space.200">
				<Stack space="space.100">
					<Heading size="small">
						<FormattedMessage {...i18n.QuickAISummaryUpsellTitleText} />
					</Heading>
					<Text color="color.text">
						<FormattedMessage
							{...i18n.QuickAISummaryUpsellBodyText}
							values={{
								link: (chunks: ReactNode[]) => (
									<Link href={ATLASSIAN_INTELLIGENCE_LINK}>{chunks}</Link>
								),
							}}
						/>
					</Text>
				</Stack>
				<Box xcss={CTAStyles}>
					<Box xcss={NumUsersTextStyles}>
						{isSiteAdmin && usersInterested > 0 && (
							<QuickAISummaryInterestedUsers usersInterested={usersInterested} />
						)}
					</Box>
					<QuickAISummaryButton isSiteAdmin={isSiteAdmin} />
				</Box>
			</Stack>
		</Box>
	);
};
