import React from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { xcss, Inline, Text } from '@atlaskit/primitives';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { token } from '@atlaskit/tokens';

const footerContainerStyles = xcss({
	position: 'relative',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	borderTop: `1px solid ${token('color.border')}`,
	paddingRight: 'space.200',
	backgroundColor: 'elevation.surface',
});

const i18n = defineMessages({
	QuickAISummaryFooterMessage: {
		id: 'experiment-quick-summary.footer.message',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description: 'Footer text for the quick AI summary popup',
	},
	atlassianIntelligenceIconAlt: {
		id: 'experiment-quick-summary.atlassian-intelligence-icon.alt-text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'alt text for the Atlassian Intelligence icon',
	},
});

export const QuickAISummaryFooter = () => {
	const { formatMessage } = useIntl();

	return (
		<Inline space="space.075" alignInline="end" xcss={footerContainerStyles}>
			<Text size="small" color="color.text.subtlest">
				{formatMessage(i18n.QuickAISummaryFooterMessage)}
			</Text>
			<AtlassianIntelligenceIcon
				label={formatMessage(i18n.atlassianIntelligenceIconAlt)}
				color={token('color.icon', '#44546F')}
			/>
		</Inline>
	);
};
