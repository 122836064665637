import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import type { ThemeProps, ThemeTokens } from '@atlaskit/button';
import { CustomThemeButton } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import type { TriggerProps } from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { useSessionData } from '@confluence/session-data';

import { QuickSummaryIcon } from './assets/QuickSummaryIcon';
import { i18n } from './i18n';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonTextWrapper = styled.span({
	fontWeight: 500,
	fontSize: '14px',
	lineHeight: '20px',
	color: token('color.text', '#172b4d'),
});

export type QuickSummaryButtonStyles = {
	buttonText?: string;
	border?: string;
	borderRadius?: string;
	padding?: string;
	standardButton?: boolean;
};

type QuickSummaryButtonProps = {
	isOpen: boolean;
	setIsOpen: (bool: boolean) => void;
	triggerProps?: TriggerProps;
	contentId: string;
	hasSeenSpotlight?: boolean;
	endSpotlight?: () => void;
	source: string;
	buttonStyles?: QuickSummaryButtonStyles;
	isCurrentUserContributor?: boolean | null;
	onOpen?: () => void; // custom onOpen callback for Advanced Search
	onClose?: () => void; // custom onClose callback for Advanced Search
	popularFeedTimeGranularity?: string;
	followingFeedSortby?: string;
};

export const QuickSummaryButton: FC<QuickSummaryButtonProps> = ({
	isOpen,
	setIsOpen,
	triggerProps,
	contentId,
	hasSeenSpotlight,
	endSpotlight,
	source,
	buttonStyles,
	isCurrentUserContributor,
	onOpen,
	onClose,
	popularFeedTimeGranularity,
	followingFeedSortby,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isAdminHubAIEnabled, edition } = useSessionData();
	const summarizeText = intl.formatMessage(i18n.quickSummaryToolTipText);
	const customTheme = (
		currentTheme: (props: ThemeProps) => ThemeTokens,
		theme: ThemeProps,
	): ThemeTokens => {
		const existingTheme = currentTheme(theme);
		const defaultStyles = {
			buttonStyles: {
				...existingTheme.buttonStyles,
				alignItems: 'center',
				gap: token('space.025', '2px'),
				'&:hover': {
					backgroundColor: `${token(
						'color.background.accent.gray.subtlest',
						'rgb(241, 242, 244)',
					)}`,
				},
				padding:
					buttonStyles?.padding || `${token('space.050', '4px')} ${token('space.100', '8px')}`,
				border: buttonStyles?.border || existingTheme.buttonStyles.border,
				borderRadius: buttonStyles?.borderRadius || existingTheme.buttonStyles.borderRadius,
			},
		};

		return {
			...existingTheme,
			...defaultStyles,
		};
	};

	const onButtonClicked = () => {
		if (!isOpen) {
			onOpen?.();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'quickSummaryIconButton',
					attributes: {
						contentId,
						hasSeenSpotlight: !!hasSeenSpotlight,
						isAIOptedIn: isAdminHubAIEnabled,
						edition,
						isCurrentUserContributor: !!isCurrentUserContributor,
						popularFeedTimeGranularity,
						followingFeedSortby,
					},
				},
			}).fire();
		} else {
			onClose?.();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'quickSummary',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'quickSummaryCloseIconButton',
					attributes: {
						contentId,
						hasSeenSpotlight: !!hasSeenSpotlight,
						isAIOptedIn: isAdminHubAIEnabled,
						edition,
						source,
					},
				},
			}).fire();
		}

		setIsOpen(!isOpen);
		endSpotlight?.();
	};

	const renderButton = () => {
		const buttonText = buttonStyles?.buttonText ?? summarizeText;

		if (buttonStyles?.standardButton) {
			return (
				<Button
					{...triggerProps}
					aria-haspopup="dialog"
					testId="quick-summary-button-new"
					isSelected={isOpen}
					onClick={onButtonClicked}
					iconBefore={() => <QuickSummaryIcon label="" />}
					aria-label={intl.formatMessage(i18n.quickSummaryLabel)}
				>
					{buttonText}
				</Button>
			);
		}

		return (
			<CustomThemeButton
				{...triggerProps}
				aria-haspopup="dialog"
				// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
				theme={customTheme}
				spacing="none"
				testId="quick-summary-button"
				appearance="subtle"
				isSelected={isOpen}
				onClick={onButtonClicked}
				iconBefore={<QuickSummaryIcon label="" />}
				aria-label={intl.formatMessage(i18n.quickSummaryLabel)}
			>
				<ButtonTextWrapper>{buttonText}</ButtonTextWrapper>
			</CustomThemeButton>
		);
	};

	return (
		<Tooltip
			hideTooltipOnMouseDown
			position={source === 'page' ? 'bottom' : 'top'}
			content={summarizeText}
		>
			{renderButton()}
		</Tooltip>
	);
};
