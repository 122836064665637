import type { FC } from 'react';
import React from 'react';

import type { TriggerProps } from '@atlaskit/popup';

import { ErrorDisplay } from '@confluence/error-boundary';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { QuickSummaryButtonWithFeatureSpotlight } from './QuickSummaryButtonWithFeatureSpotlight';
import type { QuickSummaryButtonStyles } from './QuickSummaryButton';
import { QuickSummaryButton } from './QuickSummaryButton';
import { useSpotlightEligibilityCheck } from './hooks/useSpotlightEligibilityCheck';

type QuickSummaryTriggerProps = {
	isOpen: boolean;
	setIsOpen: (bool: boolean) => void;
	triggerProps: TriggerProps;
	contentId: string;
	source: string;
	buttonStyles?: QuickSummaryButtonStyles;
	onOpen?: () => void; // custom onOpen callback for Advanced Search
	onClose?: () => void; // custom onClose callback for Advanced Search
	popularFeedTimeGranularity?: string;
	followingFeedSortby?: string;
};

export const QuickSummaryTrigger: FC<QuickSummaryTriggerProps> = ({
	isOpen,
	setIsOpen,
	triggerProps,
	contentId,
	source,
	buttonStyles,
	onOpen,
	onClose,
	popularFeedTimeGranularity,
	followingFeedSortby,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const {
		isEligibleForSpotlight,
		stopFeatureSpotlight,
		spotlightEligibilityError,
		isCurrentUserContributor,
	} = useSpotlightEligibilityCheck(contentId, source);

	const renderButton = () => {
		if (isEligibleForSpotlight) {
			return (
				<QuickSummaryButtonWithFeatureSpotlight
					contentId={contentId}
					triggerProps={triggerProps}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					source={source}
					stopFeatureSpotlight={stopFeatureSpotlight}
				/>
			);
		}

		return (
			<QuickSummaryButton
				contentId={contentId}
				triggerProps={triggerProps}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				source={source}
				buttonStyles={buttonStyles}
				onOpen={onOpen}
				onClose={onClose}
				isCurrentUserContributor={isCurrentUserContributor}
				popularFeedTimeGranularity={popularFeedTimeGranularity}
				followingFeedSortby={followingFeedSortby}
			/>
		);
	};

	return (
		<div data-vc="quick-summary-popup" {...ssrPlaceholderIdProp}>
			{renderButton()}
			{spotlightEligibilityError && <ErrorDisplay error={spotlightEligibilityError} />}
		</div>
	);
};
