import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
	/**
	 * Messages for the AI Global Opt-in component
	 */
	aiOptInDialogHeaderText: {
		id: 'ai-opt-in.dialog.header.text',
		defaultMessage: 'Work smarter, not harder',
		description: 'Header text for AI Opt In Dialog',
	},
	aiOptInDialogBodyText: {
		id: 'ai-opt-in.dialog.body.text',
		defaultMessage: `Get up to speed quickly and consume information with ease using <a>Atlassian Intelligence</a> (Al). <br></br><br></br> Interested in trying Atlassian Intelligence? Your admin can activate AI features from Atlassian Administration.`,
		description: 'Body text for AI Opt In Dialog',
	},
	aiOptInDialogLearnMoreLinkText: {
		id: 'ai-opt-in.dialog.learn-more.link.text',
		defaultMessage: 'Learn more about Atlassian Intelligence',
		description: 'Learn more link text for AI Opt In Dialog',
	},
	aiOptInDialogAdminBodyText: {
		id: 'ai-opt-in.dialog.admin.body.text',
		defaultMessage:
			'Get your team up to speed quickly and consume information with ease using <a>Atlassian Intelligence</a> (Al). <br></br><br></br> Activate Atlassian Intelligence features for your users from Atlassian Administration.',
		description: 'Body text for Admin version of AI Opt In Dialog',
	},
	aiOptInDialogAdminMaybeLaterButtonText: {
		id: 'ai-opt-in.dialog.admin.maybe-later.button.text',
		defaultMessage: 'Maybe later',
		description: 'Admin maybe later button text',
	},
	aiOptInDialogAdminGetStartedButtonText: {
		id: 'ai-opt-in.dialog.admin.get-started.button.text',
		defaultMessage: 'Get started',
		description: 'Admin get started button text',
	},
	aiOptInBannerBodyText: {
		id: 'ai-opt-in.banner.body.text',
		defaultMessage:
			'Work smarter, not harder. Express your interest in Atlassian Intelligence so your admin knows you’d like them to activate it.',
		description: 'Body text for Banner version of AI Opt In Dialog',
	},
	aiOptInBannerLearnMoreText: {
		id: 'ai-opt-in.banner.learn.more.text',
		defaultMessage: 'Learn more',
		description: 'Learn more text for Banner cta of AI Opt In Dialog',
	},
	aiOptInAdminBannerBodyText: {
		id: 'ai-opt-in.banner.admin.body.text',
		defaultMessage:
			'Get everyone up to speed quickly and consume information with ease. Turn on <a>Atlassian Intelligence</a> features for your users from Atlassian Administration.',
		description: 'Body text for Admin version of the Banner Ai Opt In',
	},
	aiOptInAdminOnlyVisible: {
		id: 'ai-opt-in.admin.only.visible.text',
		defaultMessage: 'This message is only visible to org admins.',
		description: 'Text message to let org admins know that the content is only visible to them.',
	},
});
