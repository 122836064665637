import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';

const i18n = defineMessages({
	QuickAISummaryNumInterestedUsers: {
		id: 'experiment-quick-summary.num.interested.users',
		defaultMessage:
			'{usersInterested} {usersInterested, plural, one {person on your team wants} other {people on your team want}} to try AI',
		description:
			'Text on the AI Summary upsell for admins, where {num} is the number of non-admins interested in AI',
	},
});

export const QuickAISummaryInterestedUsers = ({ usersInterested }: { usersInterested: number }) => {
	return (
		<Text size="small" color="color.text.subtle">
			<FormattedMessage {...i18n.QuickAISummaryNumInterestedUsers} values={{ usersInterested }} />
		</Text>
	);
};
