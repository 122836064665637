import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import type { CohortValues } from '../experiment-config';

export const useExperimentEnrollment = (experimentName: string) => {
	// eslint-disable-next-line confluence-feature-gating/static-feature-gates
	const [expConfig] = UNSAFE_noExposureExp(experimentName);
	const cohort = expConfig.get<CohortValues>('cohort', 'not_enrolled');

	return {
		cohort,
	};
};
