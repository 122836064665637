/*
 *  Statsig Experiment: https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/confluence_quick_summary_fg/setup
 */

export const CASF_COHORTS = {
	NOT_ENROLLED: 'not_enrolled',
	CONTROL: 'control',
	TREATMENT: 'treatment',
} as const;

export type CohortValues = (typeof CASF_COHORTS)[keyof typeof CASF_COHORTS];
