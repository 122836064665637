import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { AIBorder } from '@atlassian/ai-components';

import { useGetInterestedUsersDigestData } from '../hooks/useGetInterestedUsersDigestData';

import { QuickAISummaryFooter } from './QuickAISummaryFooter';
import { QuickAISummaryBody } from './QuickAISummaryBody';
import { QuickAISummaryHeader } from './QuickAISummaryHeader';

const PopupBody = xcss({
	borderRadius: '3px',
	display: 'flex',
	flexDirection: 'column',
	paddingTop: 'space.100',
	paddingBottom: 'space.200',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	backgroundColor: 'elevation.surface.raised',
});

const PopupContainer = xcss({
	width: '500px',
	backgroundColor: 'elevation.surface.raised',
});

export const QuickAISummaryPopupContent = () => {
	const { loading, uniqueUsersInterested } = useGetInterestedUsersDigestData();

	return (
		<Box xcss={PopupContainer}>
			<AIBorder loading={loading}>
				<QuickAISummaryHeader />
				<Box xcss={PopupBody}>
					<QuickAISummaryBody usersInterested={uniqueUsersInterested || 0} />
				</Box>
				<QuickAISummaryFooter />
			</AIBorder>
		</Box>
	);
};
