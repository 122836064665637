import gql from 'graphql-tag';

export const UpgradeUrlQuery = gql`
	query UpgradeUrlQuery($cloudId: ID!, $productKey: String!, $offeringKey: ID) {
		tenantContexts(cloudIds: [$cloudId]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					experienceCapabilities {
						changeOffering(offeringKey: $offeringKey) {
							experienceUrl
							isAvailableToUser
						}
					}
				}
			}
		}
	}
`;
