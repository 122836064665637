import React, { type FC, type MouseEvent, useEffect } from 'react';

import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button, { LinkButton } from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import GlobalOptInDefaultImageBanner from '../../../assets/global-opt-in-default-image-banner.svg';
import { ADMIN_LINK, AI_LINK } from '../../../common/constants';
import { messages } from '../../../messages';

import {
	BodyWrapper,
	ButtonContainer,
	ContentContainer,
	HeaderWrapper,
	ImageWrapper,
	OptInBackgroundBorder,
	OptInContainer,
} from './styled';
import { type AiOptInDialogProps } from './types';

const VisibleTextOrgAdminStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body.UNSAFE_small'),
});
const AiOptIn: FC<AiOptInDialogProps> = ({
	source,
	product,
	isOrgAdmin,
	image,
	headerText,
	bodyText,
	width,
	testId,
	onAdminCTA,
	onAdminClose,
	adminBodyText,
	adminHeaderText,
	adminCTAText,
	adminCloseCTAText,
	adminLink,
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onLearnMoreClicked = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiGlobalOptInDialogLearnMoreLink',
				attributes: {
					product,
					isOrgAdmin: !!isOrgAdmin,
				},
				tags: ['fabricElements'],
			},
		}).fire();
	};

	const formatMessageValues = {
		a: (s: React.ReactNode[]) => (
			<a href={AI_LINK} target="_blank" onClick={onLearnMoreClicked}>
				{s}
			</a>
		),
		br: () => <br />,
	};
	const defaultHeader = formatMessage(messages.aiOptInDialogHeaderText);
	const header = headerText ?? defaultHeader;
	const body = bodyText ?? formatMessage(messages.aiOptInDialogBodyText, formatMessageValues);
	const orgAdminBody =
		adminBodyText ?? formatMessage(messages.aiOptInDialogAdminBodyText, formatMessageValues);
	const orgAdminHeader = adminHeaderText ?? defaultHeader;

	useEffect(() => {
		const preloadImages = (paths: string[]) => {
			paths.forEach((path) => {
				const img = new Image();
				img.src = path;
			});
		};
		preloadImages([GlobalOptInDefaultImageBanner]);
	}, []);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'aiGlobalOptInDialog',
				attributes: {
					source,
					product,
					isOrgAdmin: !!isOrgAdmin,
				},
				tags: ['fabricElements'],
			},
		}).fire();
	}, [createAnalyticsEvent, source, product, isOrgAdmin]);

	const onCloseClicked = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onAdminClose?.();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiGlobalOptInDialogAdminCloseButton',
				attributes: {
					product,
				},
				tags: ['fabricElements'],
			},
		}).fire();
	};

	const onCTAClicked = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onAdminCTA?.();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiGlobalOptInDialogAdminCTAButton',
				attributes: {
					product,
				},
				tags: ['fabricElements'],
			},
		}).fire();
	};

	return (
		<OptInBackgroundBorder width={width} data-testid={testId}>
			<OptInContainer>
				{image ? (
					<ImageWrapper>{image}</ImageWrapper>
				) : (
					<ImageWrapper>
						<img src={GlobalOptInDefaultImageBanner} height="96px" alt="" />
					</ImageWrapper>
				)}
				<ContentContainer>
					{isOrgAdmin && (
						<Box xcss={VisibleTextOrgAdminStyles}>
							{formatMessage(messages.aiOptInAdminOnlyVisible)}
						</Box>
					)}
					<HeaderWrapper>{isOrgAdmin ? orgAdminHeader : header}</HeaderWrapper>
					<BodyWrapper data-testid="dialog-body-text">
						{isOrgAdmin ? orgAdminBody : body}
					</BodyWrapper>
					{isOrgAdmin && (
						<ButtonContainer>
							<Button appearance="subtle" onClick={onCloseClicked} testId="admin-close-button">
								{adminCloseCTAText ??
									formatMessage(messages.aiOptInDialogAdminMaybeLaterButtonText)}
							</Button>
							<LinkButton
								appearance="primary"
								onClick={onCTAClicked}
								href={adminLink ?? ADMIN_LINK}
								target="_blank"
								testId="admin-cta-button"
							>
								{adminCTAText ?? formatMessage(messages.aiOptInDialogAdminGetStartedButtonText)}
							</LinkButton>
						</ButtonContainer>
					)}
				</ContentContainer>
			</OptInContainer>
		</OptInBackgroundBorder>
	);
};

export default AiOptIn;
