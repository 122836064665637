import React from 'react';

import Image from '@atlaskit/image';
import { Box, xcss } from '@atlaskit/primitives';

import DarkAISummarize from './assets/DarkAISummarize.gif';
import LightAISummarize from './assets/LightAISummarize.gif';

const HeaderStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	paddingTop: 'space.200',
	paddingBottom: 'space.100',
	backgroundColor: 'elevation.surface.raised',
});

export const QuickAISummaryHeader = () => {
	return (
		<Box xcss={HeaderStyles}>
			<Image src={LightAISummarize} srcDark={DarkAISummarize} />
		</Box>
	);
};
