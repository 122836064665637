import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { SpotlightCard, SpotlightPulse } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import spotlightGif from './assets/quick_summary_spotlight.gif';
import { i18n } from './i18n';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpotlightContainer = styled.span({
	position: 'relative',
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Gif = styled.img({
	minHeight: '155px',
	width: '275px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledSpotlightCardWrapper = styled.div({
	position: 'absolute',
	zIndex: 1,
	marginTop: token('space.100', '8px'),
	right: token('space.negative.025', '-2px'),
	fontSize: '14px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledChildWrapper = styled.div({
	zIndex: 1,
	position: 'relative',
});

type SpotlightComponentProps = {
	contentId: string;
	spotlightPrompt: string;
	setIsOpen: (bool: boolean) => void;
	endSpotlight: () => void;
	children: any;
	spotlightCTAText: string;
	spotlightBodyText: string;
	source: string;
};

export const SpotlightComponent: FC<SpotlightComponentProps> = ({
	contentId,
	spotlightPrompt,
	setIsOpen,
	endSpotlight,
	children,
	spotlightBodyText,
	spotlightCTAText,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const preloadImages = (paths: string[]) => {
			paths.forEach((path) => {
				const img = new Image();
				img.src = path;
			});
		};
		preloadImages([spotlightGif]);
	}, []);

	const onTryItClicked = () => {
		setIsOpen(true);
		endSpotlight();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'quickSummary',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'quickSummarySpotlightTryItButton',
				attributes: {
					contentId,
					spotlightPrompt,
				},
			},
		}).fire();
	};

	const onSpotlightCloseClicked = () => {
		endSpotlight();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'quickSummary',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'quickSummarySpotlightCloseButton',
				attributes: {
					contentId,
					spotlightPrompt,
				},
			},
		}).fire();
	};

	return (
		<SpotlightContainer>
			<StyledChildWrapper>
				<SpotlightPulse radius={3}>{children}</SpotlightPulse>
			</StyledChildWrapper>
			<StyledSpotlightCardWrapper>
				<SpotlightCard
					key="quick-summary-icon-spotlight"
					width={275}
					image={<Gif src={spotlightGif} alt="" />}
					actions={[
						{
							text: spotlightCTAText,
							onClick: onTryItClicked,
						},
						{
							text: intl.formatMessage(i18n.quickSummarySpotlightCloseButtonText),
							onClick: onSpotlightCloseClicked,
							appearance: 'subtle',
						},
					]}
				>
					{spotlightBodyText}
				</SpotlightCard>
			</StyledSpotlightCardWrapper>
		</SpotlightContainer>
	);
};
