import gql from 'graphql-tag';

export const FeatureSpotlightUnifiedQuery = gql`
	query FeatureSpotlightUnifiedQuery($contentId: ID!) {
		getSmartContentFeature(contentId: $contentId) {
			readTime
		}
		contentContributors(id: $contentId) {
			isCurrentUserContributor
		}
	}
`;
