import { useEffect, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { createSingleQueryParamHook } from '@confluence/route-manager';
import { useSessionData } from '@confluence/session-data';

const useOpenQuickSummaryParam = createSingleQueryParamHook('openQuickSummary');

export const useQuickSummaryQueryParamOpen = (
	isOpen: boolean,
	source: string,
	setIsOpen: (bool: boolean) => void,
	contentId: string,
) => {
	const [hasSeen, setHasSeen] = useState(false);
	const { isAdminHubAIEnabled, edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isOpenQuickSummaryQueryParamValid = useOpenQuickSummaryParam() === 'true';

	useEffect(() => {
		if (!isOpen && source === 'page' && isOpenQuickSummaryQueryParamValid && !hasSeen) {
			setIsOpen(true);
			setHasSeen(true);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source,
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'quickSummaryIconButton',
					attributes: {
						contentId,
						hasSeenSpotlight: false,
						isAIOptedIn: isAdminHubAIEnabled,
						edition,
						isFromQueryParam: true,
					},
				},
			}).fire();
		}
	}, [
		isOpen,
		setIsOpen,
		isOpenQuickSummaryQueryParamValid,
		hasSeen,
		setHasSeen,
		source,
		contentId,
		createAnalyticsEvent,
		edition,
		isAdminHubAIEnabled,
	]);
};
