import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';

import type { TriggerProps } from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { QuickSummaryButton } from './QuickSummaryButton';
import { SpotlightComponent } from './SpotlightComponent';
import { i18n } from './i18n';

type QuickSummaryButtonWithFeatureSpotlightProps = {
	isOpen: boolean;
	setIsOpen: (bool: boolean) => void;
	triggerProps: TriggerProps;
	contentId: string;
	source: string;
	stopFeatureSpotlight: () => void;
};

export const QuickSummaryButtonWithFeatureSpotlight: FC<
	QuickSummaryButtonWithFeatureSpotlightProps
> = ({ isOpen, setIsOpen, triggerProps, contentId, source, stopFeatureSpotlight }) => {
	const intl = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [spotlightActive, setSpotlightActive] = useState(true);

	const endSpotlight = () => {
		stopFeatureSpotlight();
		setSpotlightActive(false);
	};

	useEffect(() => {
		if (spotlightActive) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'quickSummaryFeatureSpotlightOnePopup',
					attributes: {
						contentId,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, contentId, spotlightActive]);

	if (!spotlightActive || isOpen) {
		return (
			<>
				<QuickSummaryButton
					contentId={contentId}
					triggerProps={triggerProps}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					hasSeenSpotlight={false}
					source={source}
				/>
			</>
		);
	}

	return (
		<SpotlightComponent
			contentId={contentId}
			spotlightPrompt="featureSpotlightOne"
			endSpotlight={endSpotlight}
			spotlightBodyText={intl.formatMessage(i18n.quickSummaryFeatureSpotlightText)}
			spotlightCTAText={intl.formatMessage(i18n.quickSummarySpotlightSummarizeText)}
			setIsOpen={setIsOpen}
			source={source}
		>
			<QuickSummaryButton
				contentId={contentId}
				triggerProps={triggerProps}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				hasSeenSpotlight
				endSpotlight={endSpotlight}
				source={source}
			/>
		</SpotlightComponent>
	);
};
