import { useSitePersonalizationQuery } from '@confluence/onboarding-helpers/entry-points/hooks/useSitePersonalizationQuery';
import { useSessionData } from '@confluence/session-data';

export const GET_INTERESTED_USERS_TRAIT = 'confluence_ask_admin_to_try';

const convertToNumber = (strval: string) => {
	const numval = +strval;
	return isNaN(numval) ? undefined : numval;
};

export const useGetInterestedUsersDigestData = (skip: boolean = false) => {
	const { cloudId, isLoggedIn, isLicensed } = useSessionData();

	const shouldSkip = skip || !isLicensed || !isLoggedIn || !cloudId;

	const { attributes, loading, error } = useSitePersonalizationQuery({
		cloudId,
		skip: shouldSkip,
	});

	const sitePersonalizationDataAttributes = attributes?.reduce<Record<string, string>>(
		(obj, item) => ({ ...obj, [item.name]: item.value }),
		{},
	);

	const uniqueUsersInterested = convertToNumber(
		sitePersonalizationDataAttributes?.[GET_INTERESTED_USERS_TRAIT],
	);

	return { loading, error, uniqueUsersInterested };
};
