import { useQuery } from 'react-apollo';

import { useSessionData } from '@confluence/session-data';
import { useFeatureDiscovery } from '@confluence/feature-discovery';

import { FeatureSpotlightUnifiedQuery } from '../FeatureSpotlightUnifiedQuery.graphql';
import type {
	FeatureSpotlightUnifiedQuery as FeatureSpotlightUnifiedQueryType,
	FeatureSpotlightUnifiedQueryVariables,
} from '../__types__/FeatureSpotlightUnifiedQuery';

type SpotlightEligibilityCheckData = {
	isEligibleForSpotlight: boolean;
	stopFeatureSpotlight: () => void;
	spotlightEligibilityError?: Error;
	isCurrentUserContributor?: boolean | null;
};

export const useSpotlightEligibilityCheck = (
	contentId: string,
	source: string,
): SpotlightEligibilityCheckData => {
	const QUICK_SUMMARY_FEATURE_SPOTLIGHT_ONE =
		'confluence.frontend.quick-summary.eap.feature-spotlight-one';

	const { featureFlagClient, isAdminHubAIEnabled } = useSessionData();
	const [showFeatureSpotlight, stopFeatureSpotlight, featureSpotlightError] = useFeatureDiscovery(
		'quick.summary.ga.feature.spotlight.one',
	);

	const isQuickSummaryFeatureSpotlightOneEnabled = featureFlagClient.getBooleanValue(
		QUICK_SUMMARY_FEATURE_SPOTLIGHT_ONE,
		{
			default: false,
			shouldTrackExposureEvent: true,
		},
	);

	const { data, error: unifiedQueryError } = useQuery<
		FeatureSpotlightUnifiedQueryType,
		FeatureSpotlightUnifiedQueryVariables
	>(FeatureSpotlightUnifiedQuery, {
		variables: { contentId },
		errorPolicy: 'none',
		fetchPolicy: 'network-only',
	});

	const readTime = data?.getSmartContentFeature?.readTime ?? 0;
	const isCurrentUserContributor = data?.contentContributors?.isCurrentUserContributor;

	const isEligibleForSpotlight =
		isQuickSummaryFeatureSpotlightOneEnabled &&
		isAdminHubAIEnabled &&
		showFeatureSpotlight &&
		!featureSpotlightError &&
		source === 'page' &&
		readTime >= 4 &&
		!isCurrentUserContributor;

	const spotlightEligibilityError = featureSpotlightError || unifiedQueryError;

	return {
		isEligibleForSpotlight,
		stopFeatureSpotlight,
		spotlightEligibilityError,
		isCurrentUserContributor,
	};
};
